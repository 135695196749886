import { IFormControlDropdownItemModel } from "../control/model/form.control.field.model";
import * as csc from 'country-state-city';
import { UserStatus } from "../enums";
import HttpService from "./http.service";
import ApiUrls from "../api-urls";
import { IUserModel } from "../../features/user/model/user.model";

export default class DropdownService {

    static workflowTaskPriorityList: IFormControlDropdownItemModel[] = [
        { label: "Low", value: "1" },
        { label: "Normal", value: "2" },
        { label: "High", value: "3" }
    ];

    static async WorkflowTaskPriority(search: string): Promise<IFormControlDropdownItemModel[]> {

        if (search == null || search === "") return DropdownService.workflowTaskPriorityList;
        return DropdownService.workflowTaskPriorityList.filter(l => l.label.indexOf(search) > 0)
    }

    static async EntityType(search: string): Promise<IFormControlDropdownItemModel[]> {
        const list: IFormControlDropdownItemModel[] = [
            { label: "Others", value: "1" },
            { label: "PurchaseOrder", value: "2" },
            { label: "Workflow", value: "3" },
            { label: "WorkflowTask", value: "4" },
            { label: "DataLogger", value: "5" },
            { label: "Booking", value: "6" }
        ];

        if (search == null || search === "") return list;
        return list.filter(l => l.label.indexOf(search) > 0)
    }

    static async FileStorageType(search: string): Promise<IFormControlDropdownItemModel[]> {
        const list: IFormControlDropdownItemModel[] = [
            { label: "Link", value: "1" },
            // { label: "Upload", value: "2" }
        ];

        if (search == null || search === "") return list;
        return list.filter(l => l.label.indexOf(search) > 0)
    }

    static async UserStatusList(search: string): Promise<IFormControlDropdownItemModel[]> {
        const list: IFormControlDropdownItemModel[] = [
            { label: UserStatus[Number(UserStatus.Active)], value: `${Number(UserStatus.Active)}` },
            { label: UserStatus[Number(UserStatus.InActive)], value: `${Number(UserStatus.InActive)}` }
        ];

        if (search == null || search === "") return list;
        return list.filter(l => l.label.indexOf(search) > 0)
    }

    static async CityList(search: string, countryCode: string, stateCode: string): Promise<IFormControlDropdownItemModel[]> {
        const list: IFormControlDropdownItemModel[] = csc.City.getCitiesOfState(countryCode, stateCode).map(c => {
            const i: IFormControlDropdownItemModel = {
                label: c.name,
                value: c.name
            }
            return i;
        })

        if (search == null || search === "") return list;
        return list.filter(l => l.label.indexOf(search) > 0)
    }

    static async StateList(search: string, countryCode: string): Promise<IFormControlDropdownItemModel[]> {
        const list: IFormControlDropdownItemModel[] = csc.State.getStatesOfCountry(countryCode).map(c => {
            const i: IFormControlDropdownItemModel = {
                label: c.name,
                value: c.isoCode
            }
            return i;
        })

        if (search == null || search === "") return list;
        return list.filter(l => l.label.indexOf(search) > 0);
    }

    static async CountryList(search: string, countryCode?: string): Promise<IFormControlDropdownItemModel[]> {
        if (countryCode != null) {
            const item = csc.Country.getCountryByCode(countryCode);
            if (item == null) return [];

            return [{ label: item?.name, value: item?.isoCode }];
        }

        const list: IFormControlDropdownItemModel[] = csc.Country.getAllCountries().map(c => {
            const i: IFormControlDropdownItemModel = {
                label: c.name,
                value: c.isoCode
            }
            return i;
        })

        if (search == null || search === "") return list;
        return list.filter(l => l.label.indexOf(search) > 0);
    }

    static async SalesTeamMembersDropdown(search?: string): Promise<any[]> {
        return (await HttpService.Get<IUserModel[]>(ApiUrls.Team.GetSalesAssigneeList()));
    }

    static async WorkAllocationTeamMembersDropdown(search?: string){
        return (await HttpService.Get<IUserModel[]>(ApiUrls.Team.GetWorkAllocationAssigneeList()));
    }
}
