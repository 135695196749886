import React from "react";
import { IWorkflowModel } from "../../model/workflow.model";
import { IAppState } from "../../../../store";
import { connect } from "react-redux";
import {
    editWorkflow,
    loadWorkflowById,
    updateWorkflowDataState
} from "../../store/workflow.slice";
import { IPurchaseOrderModel } from "../../../purchase-order/model/purchase-order.model";
import { displayDate } from "../../../../common/utils";
import DropdownDisplayHelper from "../../../../common/helper/dropdown-display.helper";
import { AgGridReact } from "ag-grid-react";
import { PoType, ValidationScreens } from "../../../../common/enums";
import { IStoreSaveModel } from "../../../../common/model/store-save.model";
import { FormBaseComponent, IFormBaseState } from "../../../../common/component/form-base.component";
import { clearPurchaseOrderDataState, loadPurchaseOrderById } from "../../../purchase-order/store/purchase-order.slice";
import { AgGridDefaultColDef } from "../../../../common/app-defaults";
import ButtonGroupComponent from "../../../../common/component/button-group.component";
import { openStageAssignmentModal, IStageAssignment } from "../../../../common/stores/stage-assignment.slice";

interface IProps {
    onChange: () => void;

    purchaseOrderDataLoading: boolean;
    purchaseOrderData: IPurchaseOrderModel,
    loadPurchaseOrderById: (id: number) => void;
    clearPurchaseOrderDataState: () => void;
    workflowData: IWorkflowModel,
    loadWorkflowById: (id: number) => void,
    editWorkflow: (data: IWorkflowModel) => void;
    updateWorkflowDataState: (payload: IStoreSaveModel) => void,
    stageMoveLoading: boolean;
    workflowCreateEditLoading: boolean;
    openStageAssignmentModal: (payload: IStageAssignment) => void,
}

export interface IState extends IFormBaseState { }

class SalesReviewWorkflowComponent extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            hideForm: false
        }
    }

    get purchaseOrderId() {
        return !isNaN(this.props.workflowData?.purchaseOrderId) ? this.props.workflowData.purchaseOrderId : 0;
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.WorkflowSalesReviewStage);

        if (this.purchaseOrderId > 0) {
            await this.props.loadPurchaseOrderById(this.purchaseOrderId);
        } else {
            await this.props.clearPurchaseOrderDataState();
        }
    }

    render() {
        if (this.props.purchaseOrderDataLoading
            || this.props.stageMoveLoading
            || this.props.workflowCreateEditLoading) {
            return <div className="loading--bar fixed--top"><span></span></div>
        }
        if (this.state.hideForm) return;

        return <React.Fragment>
            <article className="card mb-4">
                <div className="card-body">
                    <div className="row">
                        <div className="col-3">
                            <div className="form-group read-only">
                                <label>PO Number</label>
                                <p>{this.props.purchaseOrderData?.poNumber?.toString()}</p>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group read-only">
                                <label>PO Date</label>
                                <p>{displayDate(this.props.purchaseOrderData.poDate)}</p>
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="form-group read-only">
                                <label>Amendment Number</label>
                                <p>{this.props.purchaseOrderData.amendmentNumber?.toString() ?? "-"}</p>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group read-only">
                                <label>Amendment Date</label>
                                <p>{displayDate(this.props.purchaseOrderData.amendmentDate?.toString())}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <div className="form-group read-only">
                                <label>Quotation Number</label>
                                <p>{this.props.purchaseOrderData.quotationNumber?.toString() ?? "-"}</p>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group read-only">
                                <label>Quotation Date</label>
                                <p>{displayDate(this.props.purchaseOrderData.quotationDate?.toString())}</p>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group read-only">
                                <label htmlFor="referenceNote">Reference Note</label>
                                <p>{this.props.purchaseOrderData.referenceNote?.toString() ?? "-"}</p>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group read-only">
                                <label htmlFor="referenceNote">Description</label>
                                <p>{this.props.purchaseOrderData.description?.toString() ?? "-"}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <div className="form-group read-only">
                                <label>Client</label>
                                <p>{this.props.purchaseOrderData.client != null
                                    ? DropdownDisplayHelper.Client(this.props.purchaseOrderData.client) : "-"}
                                </p>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group read-only">
                                <label>Billing Address</label>
                                <p>{this.props.purchaseOrderData.billingAddress != null
                                    ? DropdownDisplayHelper.ClientAddress(this.props.purchaseOrderData.billingAddress) : "-"}
                                </p>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group  read-only">
                                <label>Delivery Address</label>
                                <p>{this.props.purchaseOrderData.deliveryAddress != null
                                    ? DropdownDisplayHelper.ClientAddress(this.props.purchaseOrderData.deliveryAddress) : "-"}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
            <article className="card mb-4">
                <div className="card-header card-form-header">
                    <div className="card-form-header-title">Line Items</div>
                </div>
                <div className="card-block ag-theme-alpine medium-grid">
                    {(() => {
                        if (this.props.purchaseOrderData.lineItems == null || this.props.purchaseOrderData.lineItems.length == 0) return;
                        return <AgGridReact
                            unSortIcon={true}
                            defaultColDef={AgGridDefaultColDef}
                            rowHeight={60}
                            columnDefs={[
                                {
                                    suppressMovable: true,
                                    headerName: "Code", field: "service",
                                    flex: 1, sortable: true, cellClass: "grid-cell",
                                    cellRenderer: (params) => <span>{params.value?.code}</span>
                                },
                                {
                                    suppressMovable: true,
                                    headerName: "Service", field: "service",
                                    flex: 3, sortable: true, cellClass: "grid-cell",
                                    cellRenderer: (params) => <span>{params.value?.title}</span>
                                },
                                {
                                    suppressMovable: true,
                                    headerName: "HSN/SAC", field: "service",
                                    flex: 1.5, sortable: true, cellClass: "grid-cell",
                                    cellRenderer: (params) => <span>{params.value?.hsnSacCode}</span>
                                },
                                {
                                    suppressMovable: true,
                                    headerName: "Type", field: "type",
                                    flex: 1.5, sortable: true, cellClass: "grid-cell",
                                    cellRenderer: (params) => <span>{PoType[Number(params.value)]}</span>
                                },
                                {
                                    suppressMovable: true,
                                    headerName: "Description", field: "description",
                                    flex: 3, sortable: true, cellClass: "grid-cell",
                                    cellRenderer: (params) => <span>{params.value}</span>
                                },
                                {
                                    suppressMovable: true,
                                    headerName: "Total Qty", field: "quantity",
                                    flex: 1, sortable: true, cellClass: "grid-cell",
                                    cellRenderer: (params) => <span>{params.value}</span>
                                },
                                {
                                    suppressMovable: true,
                                    headerName: "Served Qty", field: "availableQuantity",
                                    width: 120, sortable: true, cellClass: "grid-cell",
                                    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                        let first = nodeA.data?.quantity - nodeA.data?.availableQuantity;
                                        let second = nodeB.data?.quantity - nodeB.data?.availableQuantity;
                                        
                                        return (first > second) ? 1 : -1;
                                    },
                                    cellRenderer: (params) => <span>{params.data?.quantity - params?.data?.availableQuantity}</span>
                                },
                                {
                                    suppressMovable: true,
                                    headerName: "Remaining Qty", field: "availableQuantity",
                                    width:120, sortable: true, cellClass: "grid-cell",
                                    cellRenderer: (params) => <span>{params.value}</span>
                                },
                                
                                {
                                    suppressMovable: true,
                                    headerName: "Rate", field: "unitRate",
                                    flex: 1.5, sortable: true, cellClass: "grid-cell",
                                    cellRenderer: (params) => <span>{params.value}</span>
                                },
                                {
                                    suppressMovable: true,
                                    headerName: "Measurement", field: "unitMeasurement",
                                    flex: 2, sortable: true, cellClass: "grid-cell",
                                    cellRenderer: (params) => <span>{params.value}</span>
                                },
                                {
                                    suppressMovable: true,
                                    headerName: "Amount", field: "totalAmount",
                                    flex: 1.5, sortable: true, cellClass: "grid-cell",
                                    cellRenderer: (params) => <span>{params.value}</span>
                                }
                            ]}
                            pagination={true}
                            paginationAutoPageSize={true}
                            rowData={this.props.purchaseOrderData.lineItems}>
                        </AgGridReact>
                    })()}
                </div>
            </article>
            {/* <article className="card mb-4">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="salesReviewNotes">Sales Review Note</label>
                                <textarea id="salesReviewNotes" className="form-control" rows={4}
                                    value={this.props.workflowData.salesReviewNotes?.toString() ?? ""}
                                    onChange={e => {
                                        e.preventDefault();
                                        this.props.updateWorkflowDataState({ name: "salesReviewNotes", value: e.target.value })
                                    }} />
                            </div>
                        </div>
                    </div>
                </div>
            </article> */}
            <ButtonGroupComponent>
                <>
                    <button type="button" className="btn btn--secondary btn--right"
                        disabled={this.props.stageMoveLoading}
                        onClick={e => {
                            e.preventDefault();

                            const error = !this.ValidateForm(this.props.workflowData);
                            this.reloadForm();
                            if (error) return;
                            // ---- open Stage assignment modal ----
                            this.props.openStageAssignmentModal({
                                optionsList: this.props.workflowData.nextStageAssigneeList,
                                openModal: true,
                                proceedBtnClicked: true,
                            });

                        }}>Proceed</button>
                    <button type="button" className="btn btn--red btn--right"
                        disabled={this.props.stageMoveLoading}
                        onClick={e => {
                            e.preventDefault();
                            // ---- open Stage assignment modal ----
                            this.props.openStageAssignmentModal({
                                optionsList: this.props.workflowData.rejectStageAssigneeList,
                                openModal: true,
                                proceedBtnClicked: false,
                            });

                        }}>Reject</button>
                    <button type="button" className="btn btn--primary-d2 btn--right"
                        disabled={this.props.stageMoveLoading}
                        onClick={e => {
                            e.preventDefault();

                            this.props.editWorkflow(this.props.workflowData);
                            this.props.onChange();
                        }}>Save</button>
                </>
            </ButtonGroupComponent>
        </React.Fragment>
    }
}

const mapStateToProps = (state: IAppState) => ({
    workflowData: state.workflow.workflowData,
    workflowCreateEditLoading: state.workflow.createEditLoading,
    stageMoveLoading: state.workflow.stageMoveLoading,
    purchaseOrderDataLoading: state.purchaseOrder.dataLoading,
    purchaseOrderData: state.purchaseOrder.purchaseOrderData,
})
export default connect(mapStateToProps, {
    editWorkflow,
    loadWorkflowById,
    updateWorkflowDataState,
    loadPurchaseOrderById,
    clearPurchaseOrderDataState,
    openStageAssignmentModal
})(SalesReviewWorkflowComponent);
