import React from "react";
import { BaseComponent } from "../../../common/component/base.component";
import { IDataLoggerModel } from "../model/data-logger.model";
import {
  loadDataLoaders,
  toggleView,
  changeCurrentPage,
} from "../store/data-logger.slice";
import { IAppState } from "../../../store";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ScreenUrls from "../../../common/screen-urls";
import { AgGridReact } from "ag-grid-react";
import { DeferredLoadOptions, displayDate } from "../../../common/utils";
import { AccessLevel } from "../../../common/enums";
import { AgGridDefaultColDef } from "../../../common/app-defaults";
import SearchBox from "../../../common/component/searchbox.component";
import CategoryService from "../../../common/service/category.service";
import DataLoggerMakeService from "../service/data-logger-make.service";
import AsyncSelect from "react-select/async";
import DropdownDisplayHelper from "../../../common/helper/dropdown-display.helper";
import { IDataLoggerFilterRequestModel } from "../model/request/data-logger-filter.rquest.model";
import { ICategoryModel } from "../../../common/model/category.model";
import { IDataLoggerMakeModel } from "../model/data-logger-make.model";
import DataLoggerItemCardComponent from "../component/data-logger-item-card.component";
import FileService from "../../../common/service/file.service";
import DataLoggerService from "../service/data-logger.service";
import GridDeleteButtonControl from "../../../common/control/component/grid/grid-delete-buton.control";
import PaginationComponent from "../../../common/component/pagination.component";
import { IPaginationModel } from "../../../common/model/pagination.model";

export interface IProps {
  accessLevel: number;
  dataLoggerList: IDataLoggerModel[];
  listLoading: boolean;
  loadDataLoaders: (request?: IDataLoggerFilterRequestModel) => void;
  toggleView: any;
  gridView: boolean;
  changeCurrentPage: (data) => void;
  pagination: IPaginationModel;
}

class DataLoggerPage extends BaseComponent<
  IProps,
  {
    hideCategory: boolean;
    hideSubCategory: boolean;
    category?: ICategoryModel | null;
    subCategory?: ICategoryModel | null;
    make?: IDataLoggerMakeModel | null;
    serialNumber: string;
  }
> {
  gridRef: React.RefObject<unknown>;
  constructor(props) {
    super(props);
    this.gridRef = React.createRef();
    this.state = {
      hideCategory: false,
      hideSubCategory: false,
      category: null,
      subCategory: null,
      make: null,
      serialNumber: "",
    };
  }

  async componentDidMount() {
    await this.reloadDataLoggers();
    this.reloadSubCategory();
  }

  async reloadDataLoggers() {
    setTimeout(async () => {
      await this.props.loadDataLoaders({
        categoryId: this.state.category?.id,
        subCategoryId: this.state.subCategory?.id,
        makeId: this.state.make?.id,
        serialNumber: this.state.serialNumber,
        page: this.props.pagination.page,
        pageSize: this.props.pagination.pageSize,
      });
    }, 200);
  }

  loadCategoryOption = (inputValue: string) =>
    DeferredLoadOptions(inputValue, (search: string) =>
      CategoryService.LoadCategoriesList({
        searchStr: search,
        onlyParents: true,
      })
    );

  loadSubCategoryOption = (inputValue: string) =>
    DeferredLoadOptions(inputValue, (search: string) =>
      CategoryService.LoadCategoriesList({
        searchStr: search,
        parentId: this.state.category?.id.toString() ?? "0",
      })
    );
  loadMakersOption = (inputValue: string) =>
    DeferredLoadOptions(inputValue, DataLoggerMakeService.MakeDropdown);

  reloadCategory = () => {
    this.setState({ hideCategory: true });
    setTimeout(() => {
      this.setState({ hideCategory: false });
    }, 1);
  };

  reloadSubCategory = () => {
    this.setState({ hideSubCategory: true });
    setTimeout(() => {
      this.setState({ hideSubCategory: false });
    }, 1);
  };

  updateState(newState: any) {
    setTimeout(() => {
      this.setState({
        ...this.state,
        ...newState,
      });
    }, 1);
  }

  render() {
    return (
      <div className="pcoded-content">
        <div className="pcoded-inner-content">
          <div className="main-body">
            <div className="page-wrapper">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    {(() => {
                      if (this.props.listLoading)
                        return (
                          <div className="loading--bar">
                            <span></span>
                          </div>
                        );
                    })()}
                    <div className="card-header">
                      <h5 className="d-flex align-items-center">
                        List of DataLoggers
                        <div className="grid-tile-toggle-btn">
                          <button
                            className={`${this.props.gridView ? "" : "active"}`}
                            onClick={() =>
                              this.props.gridView && this.props.toggleView()
                            }
                          >
                            Tile View
                          </button>
                          <button
                            className={`${this.props.gridView ? "active" : ""}`}
                            onClick={() =>
                              !this.props.gridView && this.props.toggleView()
                            }
                          >
                            Grid View
                          </button>
                        </div>
                      </h5>

                      <div className="card-header-right">
                        <div className="btn-group card-option">
                          {(() => {
                            if (this.props.accessLevel >= AccessLevel.Create) {
                              return (
                                <Link
                                  className="btn drp-icon btn-rounded btn-primary"
                                  to={ScreenUrls.DataLoggers.Create()}
                                >
                                  Add New
                                </Link>
                              );
                            }
                          })()}
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="parentCategoryId">Category</label>
                            {(() => {
                              if (this.state.hideCategory) return;
                              return (
                                <AsyncSelect
                                  id="parentCategoryId"
                                  cacheOptions
                                  defaultOptions
                                  isClearable={true}
                                  loadOptions={this.loadCategoryOption}
                                  value={
                                    this.state.category != null
                                      ? {
                                        label: DropdownDisplayHelper.Category(
                                          this.state.category
                                        ),
                                        value:
                                          this.state.category.id.toString(),
                                      }
                                      : { label: "Select Category", value: "" }
                                  }
                                  onChange={async (e) => {
                                    if (e === null) {
                                      this.updateState({
                                        subCategory: null,
                                        category: null,
                                      });
                                      this.props.changeCurrentPage(1);
                                      this.reloadSubCategory();
                                      this.reloadCategory();
                                      await this.reloadDataLoggers();
                                      return;
                                    }

                                    const cId = Number(e?.value);
                                    if (cId === null || cId === 0) return;

                                    const category =
                                      await CategoryService.GetById(cId);
                                    this.updateState({ category: category });
                                    this.props.changeCurrentPage(1);
                                    this.reloadSubCategory();
                                    await this.reloadDataLoggers();
                                  }}
                                />
                              );
                            })()}
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="subCategoryId">Sub Category</label>
                            {(() => {
                              if (this.state.hideSubCategory) return;
                              return (
                                <AsyncSelect
                                  id="subCategoryId"
                                  cacheOptions
                                  defaultOptions
                                  isClearable={true}
                                  loadOptions={this.loadSubCategoryOption}
                                  isDisabled={this.state.category == null}
                                  value={
                                    this.state.subCategory != null
                                      ? {
                                        label: DropdownDisplayHelper.Category(
                                          this.state.subCategory
                                        ),
                                        value:
                                          this.state.subCategory.id.toString(),
                                      }
                                      : {
                                        label: "Select Sub Category",
                                        value: "",
                                      }
                                  }
                                  onChange={async (e) => {
                                    if (e == null) {
                                      this.updateState({ subCategory: null });
                                      this.props.changeCurrentPage(1);
                                      this.reloadSubCategory();
                                      await this.reloadDataLoggers();
                                      return;
                                    }

                                    const cId = Number(e?.value);
                                    if (cId === null || cId === 0) return;

                                    const subCategory =
                                      await CategoryService.GetById(cId);
                                    this.updateState({
                                      subCategory: subCategory,
                                    });
                                    this.props.changeCurrentPage(1);
                                    await this.reloadDataLoggers();
                                  }}
                                />
                              );
                            })()}
                          </div>
                        </div>

                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="dtGridMakeId">Make</label>
                            <AsyncSelect
                              id="dtGridMakeId"
                              cacheOptions
                              defaultOptions
                              isClearable={true}
                              loadOptions={this.loadMakersOption}
                              value={
                                this.state.make != null
                                  ? {
                                    label:
                                      DropdownDisplayHelper.DataLoggerMake(
                                        this.state.make
                                      ),
                                    value: this.state.make.id.toString(),
                                  }
                                  : { label: "Select Make", value: "" }
                              }
                              onChange={async (e) => {
                                if (e == null) {
                                  this.updateState({ make: null });
                                  this.props.changeCurrentPage(1);
                                  await this.reloadDataLoggers();
                                  return;
                                }

                                const mId = Number(e?.value);
                                if (mId === null || mId === 0) return;

                                const make =
                                  await DataLoggerMakeService.GetById(mId);
                                this.updateState({ make: make });
                                this.props.changeCurrentPage(1);
                                await this.reloadDataLoggers();
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-3">
                          <SearchBox
                            label="Serial Number"
                            htmlFor="dtGridSerialNumber"
                            value={this.state.serialNumber}
                            onBlurHandler={async (e) => {
                              if (e != null) {
                                this.props.changeCurrentPage(1);
                                this.updateState({
                                  serialNumber: e.target.value,
                                });
                                await this.reloadDataLoggers();
                              }
                            }}
                          />
                        </div>
                      </div>

                      {!this.props?.listLoading && (
                        <>
                          <div className="row">
                            {this.props.gridView ? (
                              <div className="col-12">
                                <div className="card-block px-0 ag-theme-alpine">
                                  <AgGridReact
                                    domLayout="autoHeight"
                                    pagination={false}
                                    paginationAutoPageSize={true}
                                    unSortIcon={true}
                                    rowHeight={60}
                                    defaultColDef={AgGridDefaultColDef}
                                    columnDefs={[
                                      {
                                        suppressMovable: true,
                                        hide:
                                          this.props.accessLevel <
                                          AccessLevel.Edit,
                                        headerName: "Edit",
                                        field: "id",
                                        width: 50,
                                        cellClass: "grid-cell grid-cell-link",
                                        cellRenderer: (params) => {
                                          return params.data.isAvailable ? (
                                            <Link
                                              to={ScreenUrls.DataLoggers.Edit(
                                                params.value
                                              )}
                                            >
                                              <i
                                                className={`feather icon-edit`}
                                              ></i>
                                            </Link>
                                          ) : (
                                            ""
                                          );
                                        },
                                      },
                                      {
                                        suppressMovable: true,
                                        headerName: "Calibration Certificate",
                                        field: "calibrationCertificate",
                                        width: 90,
                                        cellClass:
                                          "grid-cell grid-cell-link download",
                                        cellRenderer: (params) => {
                                          if (
                                            params.value != null &&
                                            params.value?.publicLink
                                          ) {
                                            return (
                                              <button
                                                style={{ borderRadius: "15px" }}
                                                className="text-capitalize font-12 m-0 pill active-pill align-self-end"
                                                onClick={e => {
                                                  e.preventDefault();
                                                  window.open(params.value?.publicLink, '_blank');
                                                }}>Open Link</button>

                                            );
                                          }
                                        },
                                      },
                                      {
                                        suppressMovable: true,
                                        hide:
                                          this.props.accessLevel <
                                          AccessLevel.Delete,
                                        headerName: "Delete",
                                        field: "id",
                                        width: 50,
                                        cellClass:
                                          "grid-cell grid-cell-link delete",
                                        cellRenderer: (params) => {
                                          return params.data.isAvailable ? (
                                            <GridDeleteButtonControl
                                              onDelete={async () => {
                                                await DataLoggerService.Delete(
                                                  params.value
                                                );
                                                this.props.loadDataLoaders();
                                              }}
                                            ></GridDeleteButtonControl>
                                          ) : (
                                            ""
                                          );
                                        },
                                      },
                                      {
                                        suppressMovable: true,
                                        headerName: "#",
                                        field: "id",
                                        width: 100,
                                        cellClass: "grid-cell",
                                        cellRenderer: (params) => {
                                          return <span>{params.value}</span>;
                                        },
                                      },
                                      {
                                        suppressMovable: true,
                                        headerName: "Name",
                                        field: "name",
                                        flex: 3,
                                        sortable: true,
                                        cellClass: "grid-cell",
                                        cellRenderer: (params) => {
                                          return <span>{params.value}</span>;
                                        },
                                      },
                                      {
                                        suppressMovable: true,
                                        headerName: "Category",
                                        flex: 2,
                                        field: "category",
                                        sortable: true,
                                        cellClass: "grid-cell",
                                        comparator: (
                                          valueA,
                                          valueB,
                                          nodeA,
                                          nodeB,
                                          isDescending
                                        ) => {
                                          if (valueA?.name == valueB?.name)
                                            return 0;
                                          return valueA?.name > valueB?.name
                                            ? 1
                                            : -1;
                                        },
                                        cellRenderer: (params) => {
                                          return (
                                            <span>{params.value?.name}</span>
                                          );
                                        },
                                      },
                                      {
                                        suppressMovable: true,
                                        headerName: "Make",
                                        flex: 3,
                                        field: "make",
                                        sortable: true,
                                        cellClass: "grid-cell",
                                        comparator: (
                                          valueA,
                                          valueB,
                                          nodeA,
                                          nodeB,
                                          isDescending
                                        ) => {
                                          if (valueA?.name == valueB?.name)
                                            return 0;
                                          return valueA?.name > valueB?.name
                                            ? 1
                                            : -1;
                                        },
                                        cellRenderer: (params) => {
                                          return (
                                            <span>{params.value?.name}</span>
                                          );
                                        },
                                      },
                                      {
                                        suppressMovable: true,
                                        headerName: "Serial #",
                                        flex: 3,
                                        field: "serialNumber",
                                        sortable: true,
                                        cellClass: "grid-cell",
                                        cellRenderer: (params) => {
                                          return <span>{params.value}</span>;
                                        },
                                      },
                                      {
                                        suppressMovable: true,
                                        headerName: "Last Calibrate",
                                        flex: 2,
                                        field: "lastCalibratedOn",
                                        sortable: true,
                                        cellClass: "grid-cell",
                                        cellRenderer: (params) => {
                                          return (
                                            <span>
                                              {displayDate(params.value)}
                                            </span>
                                          );
                                        },
                                      },
                                      {
                                        suppressMovable: true,
                                        headerName: "Calibration Due",
                                        flex: 2,
                                        field: "calibrationDueOn",
                                        sortable: true,
                                        cellClass: "grid-cell",
                                        cellRenderer: (params) => {
                                          return (
                                            <span>
                                              {displayDate(params.value)}
                                            </span>
                                          );
                                        },
                                      },
                                      {
                                        suppressMovable: true,
                                        headerName: "Health",
                                        field: "isDamaged",
                                        width: 120,
                                        sortable: true,
                                        cellClass: "grid-cell grid-cell-tag",
                                        cellRenderer: (params) => {
                                          return (
                                            <label
                                              className={
                                                !params.value
                                                  ? "active m-0"
                                                  : "inactive-red m-0"
                                              }
                                              style={{ borderRadius: "15px" }}
                                            >
                                              {!params.value
                                                ? "Healthy"
                                                : "Damaged"}
                                            </label>
                                          );
                                        },
                                      },
                                      {
                                        suppressMovable: true,
                                        headerName: "Availability",
                                        field: "isAvailable",
                                        width: 120,
                                        sortable: true,
                                        cellClass: "grid-cell grid-cell-tag",
                                        cellRenderer: (params) => {
                                          return (
                                            <label
                                              className={
                                                params.data.isExpired
                                                  ? "inactive-red m-0"
                                                  : !params.value
                                                    ? "inactive m-0"
                                                    : "active m-0"
                                              }
                                              style={{ borderRadius: "15px" }}
                                            >
                                              {params.data.isExpired
                                                ? "Expired"
                                                : params.value
                                                  ? "Available"
                                                  : "Booked"}
                                            </label>
                                          );
                                        },
                                      },
                                      {
                                        suppressMovable: true,
                                        headerName: "Action Buttons",
                                        cellClass:
                                          "grid-cell hide-column grid-cell-tag",
                                        headerClass: "hide-column",
                                        pinned: "right",
                                        cellRenderer: (params) => {
                                          return (
                                            <div className="d-flex p-3 datalogger-tooltip">
                                              <div className="w-50">
                                                <div className="mb-1">
                                                  Name : {params.data?.name}
                                                </div>
                                                <div className="mb-1">
                                                  Category :{" "}
                                                  {params.data?.category?.name}
                                                </div>
                                                <div className="mb-1">
                                                  Make :{" "}
                                                  {params.data?.make?.name}
                                                </div>
                                                {!params.data?.isAvailable && !params.data?.isDamaged && !params.data?.isExpired
                                                  && (
                                                    <div>
                                                      Client :{" "}
                                                      {
                                                        params.data?.booking[0]
                                                          ?.client?.companyName
                                                      }
                                                    </div>
                                                  )}
                                              </div>
                                              <div className="w-50">
                                                <div className="mb-1">
                                                  Serial :{" "}
                                                  {params.data?.serialNumber}
                                                </div>
                                                <div className="mb-1">
                                                  Last calibrate :{" "}
                                                  {displayDate(
                                                    params.data
                                                      ?.lastCalibratedOn
                                                  )}
                                                </div>
                                                <div className="mb-1">
                                                  Calibration due :{" "}
                                                  {displayDate(
                                                    params.data
                                                      ?.calibrationDueOn
                                                  )}
                                                </div>
                                                {!params.data?.isAvailable && !params.data?.isDamaged && !params.data?.isExpired
                                                  && (
                                                    <div>
                                                      Booking modified on :
                                                      {displayDate(
                                                        params.data?.booking[0]
                                                          ?.lastUpdatedOnUtc ??
                                                        params.data?.booking[0]
                                                          ?.createdOnUtc
                                                      )}
                                                    </div>
                                                  )}
                                              </div>
                                            </div>
                                          );
                                        },
                                      },
                                    ]}
                                    rowData={this.props.dataLoggerList}
                                  >
                                    ref={this.gridRef}
                                  </AgGridReact>
                                </div>
                              </div>
                            ) : this.props?.dataLoggerList?.length > 0 ? (
                              this.props?.dataLoggerList?.map((item) => (
                                <DataLoggerItemCardComponent
                                  key={item.id}
                                  item={item}
                                  accessLevel={this.props.accessLevel}
                                />
                              ))
                            ) : (
                              <div className="col-12 text-center">
                                Data not found
                              </div>
                            )}
                          </div>
                          <div className="row">
                            <div className="col-12 position-static">
                              <PaginationComponent
                                itemsPerPage={this.props.pagination.pageSize}
                                currentPage={this.props.pagination.page}
                                totalRecords={this.props.pagination.total}
                                onPageChangeHandler={(data) => {
                                  this.props.changeCurrentPage(
                                    data.selected + 1
                                  );
                                  this.reloadDataLoggers();
                                }}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  dataLoggerList: state.dataLogger.dataLoggerList,
  listLoading: state.dataLogger.listLoading,
  gridView: state.dataLogger.gridView,
  pagination: state.dataLogger.pagination,
});

export default connect(mapStateToProps, {
  loadDataLoaders,
  toggleView,
  changeCurrentPage,
})(DataLoggerPage);
