import React from "react";
import { IAppState } from "../../../../store";
import { connect } from "react-redux";
import { editWorkflow, updateWorkflowDataState } from "../../store/workflow.slice";
import { IWorkflowModel } from "../../model/workflow.model";
import { IPurchaseOrderModel } from "../../../purchase-order/model/purchase-order.model";
import {
    clearPurchaseOrderDataState,
    editPurchaseOrder, loadPurchaseOrderById
} from "../../../purchase-order/store/purchase-order.slice";
import { AccessLevel, ValidationScreens } from "../../../../common/enums";
import { IStoreSaveModel } from "../../../../common/model/store-save.model";
import { FormBaseComponent, IFormBaseState } from "../../../../common/component/form-base.component";
import ScreenUrls from "../../../../common/screen-urls";
import PurchaseOrderCreateEditComponent from "../../../purchase-order/component/purchase-order-create-edit.component";
import { setReturnUrl } from "../../../user/store/user-session.slice";
import ButtonGroupComponent from "../../../../common/component/button-group.component";
import { openStageAssignmentModal, IStageAssignment } from "../../../../common/stores/stage-assignment.slice";
interface IProps {
    onChange: () => void,
    workflowData: IWorkflowModel,
    stageMoveLoading: boolean;
    purchaseOrderCreateEditLoading: boolean;
    purchaseOrderDataLoading: boolean;
    purchaseOrderData: IPurchaseOrderModel,
    editWorkflow: (data: IWorkflowModel) => void;
    updateWorkflowDataState: (payload: IStoreSaveModel) => void;
    loadPurchaseOrderById: (id: number) => void;
    editPurchaseOrder: (data: IPurchaseOrderModel) => void;
    clearPurchaseOrderDataState: () => void;
    setReturnUrl: (url: string) => void;
    isNavCollapsed: boolean,
    openStageAssignmentModal: (payload: IStageAssignment) => void,
}
export interface IState extends IFormBaseState {
    redirectUrl: string
}
class SalesDataEntryWorkflowComponent extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            hideForm: false,
            redirectUrl: ""
        }
    }

    get workflowId() {
        return !isNaN(this.props.workflowData?.id) ? Number(this.props.workflowData.id) : 0;
    }

    get purchaseOrderId() {
        return !isNaN(this.props.workflowData?.purchaseOrderId) ? this.props.workflowData.purchaseOrderId : 0;
    }


    async componentDidMount() {
        let returnUrl = "";
        if (this.workflowId > 0 && this.purchaseOrderId > 0) {
            await this.props.loadPurchaseOrderById(this.purchaseOrderId);
            returnUrl = ScreenUrls.Workflows.Edit(this.workflowId);
        } else {
            await this.props.clearPurchaseOrderDataState();
            returnUrl = ScreenUrls.Workflows.Edit(this.workflowId);
        }
        await this.props.setReturnUrl(returnUrl);
    }

    render() {
        if (this.props.purchaseOrderDataLoading || this.props.stageMoveLoading || this.props.purchaseOrderCreateEditLoading) {
            return <div className="loading--bar fixed--top"><span></span></div>
        }

        if (this.state.hideForm) return;

        return <React.Fragment>
            <PurchaseOrderCreateEditComponent id={this.purchaseOrderId.toString()}
                accessLevel={AccessLevel.Edit}
                accessLevelLineItem={AccessLevel.Create}
                updateHasError={(val: boolean) => this.setHasError(val)} />


            <ButtonGroupComponent>
                <>
                    <button type="button" className="btn btn--secondary btn--right"
                        disabled={this.props.purchaseOrderCreateEditLoading || this.props.stageMoveLoading}
                        onClick={async (e) => {
                            e.preventDefault();

                            await this.setValidator(ValidationScreens.WorkflowSalesDataEntryStage);
                            const workflowError = !this.ValidateForm(this.props.workflowData);
                            this.reloadForm();
                            if (workflowError) return;

                            await this.setValidator(ValidationScreens.PurchaseOrderWithPOItem);
                            const poError = !this.ValidateForm(this.props.purchaseOrderData);
                            this.reloadForm();
                            if (poError) return;
                            // ---- open Stage assignment modal ----
                            this.props.openStageAssignmentModal({
                                optionsList: this.props.workflowData.nextStageAssigneeList,
                                openModal: true,
                                proceedBtnClicked: true,
                            });


                        }}>Proceed</button>
                    <button type="button" className="btn btn--primary-d2 btn--right"
                        disabled={this.props.purchaseOrderCreateEditLoading || this.props.stageMoveLoading}
                        onClick={async (e) => {
                            e.preventDefault();

                            // await this.setValidator(ValidationScreens.WorkflowSalesDataEntryStage);
                            // const workflowError = !this.ValidateForm(this.props.workflowData);
                            // this.reloadForm();
                            // if (workflowError) return;

                            // await this.setValidator(ValidationScreens.PurchaseOrderWithPOItem);
                            // const poError = !this.ValidateForm(this.props.purchaseOrderData);
                            // this.reloadForm();
                            // if (poError) return;

                            await this.props.editPurchaseOrder(this.props.purchaseOrderData);
                            await this.props.editWorkflow(this.props.workflowData);
                            this.props.onChange();
                        }}>Save</button>
                </>

            </ButtonGroupComponent>
        </React.Fragment>
    }
}

const mapStateToProps = (state: IAppState) => ({
    workflowData: state.workflow.workflowData,
    stageMoveLoading: state.workflow.stageMoveLoading,
    purchaseOrderCreateEditLoading: state.purchaseOrder.createEditLoading,
    purchaseOrderDataLoading: state.purchaseOrder.dataLoading,
    purchaseOrderData: state.purchaseOrder.purchaseOrderData,
    isNavCollapsed: state.userSession.isNavCollapsed,
})
export default connect(mapStateToProps, {
    editWorkflow,
    updateWorkflowDataState,
    loadPurchaseOrderById,
    editPurchaseOrder,
    clearPurchaseOrderDataState,
    setReturnUrl,
    openStageAssignmentModal,
})(SalesDataEntryWorkflowComponent);
