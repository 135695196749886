import React from "react";
import { IWorkflowModel } from "../../model/workflow.model";
import { IAppState } from "../../../../store";
import { connect } from "react-redux";
import { editWorkflow, updateWorkflowDataState } from "../../store/workflow.slice";
import { AgGridReact } from "ag-grid-react";

import { IStoreSaveModel } from "../../../../common/model/store-save.model";
import DropdownDisplayHelper from "../../../../common/helper/dropdown-display.helper";
import { Link } from "react-router-dom";
import ScreenUrls from "../../../../common/screen-urls";
import ValidationMessageControl from "../../../../common/control/validation-message.control";
import { FormBaseComponent, IFormBaseState } from "../../../../common/component/form-base.component";
import { PoType, ValidationScreens, WorkflowTaskPriority } from "../../../../common/enums";
import { AgGridDefaultColDef } from "../../../../common/app-defaults";
import { openStageAssignmentModal, IStageAssignment } from "../../../../common/stores/stage-assignment.slice";
import ButtonGroupComponent from "../../../../common/component/button-group.component";
import WorkAllocationModal from "../../component/work-allocation-modal.component";
import { IPurchaseOrderLineItemModel } from "../../../purchase-order/model/purchase-order-item.model";

interface IProps {
    workflowData: IWorkflowModel,
    editWorkflow: (data: IWorkflowModel) => void;
    onChange: () => void;
    stageMoveLoading: boolean;
    updateWorkflowDataState: (payload: IStoreSaveModel) => void;
    openStageAssignmentModal: (payload: IStageAssignment) => void,
}

export interface IState extends IFormBaseState {
    toggle: boolean,
    purchaseOrderItem: null | IPurchaseOrderLineItemModel,
}

class TasksWorkflowComponent extends FormBaseComponent<IProps, IState> {

    constructor(props) {
        super(props);
        this.state = {
            hideForm: false,
            toggle: false,
            purchaseOrderItem: null,
        }
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.WorkflowTasksStage);
    }

    render() {
        if (this.state.hideForm) return;
        return <React.Fragment>
            {/* Work allocation */}
            {
                (() => {
                    if (!this.props.workflowData?.purchaseOrderItems) return;
                    return (
                        <article className="card mb-4">
                            <div className="card-header card-form-header">
                                <div className="card-form-header-title">Work Allocation</div>
                            </div>
                            <div className="card-block ag-theme-alpine medium-grid">
                                {(() => {
                                    return <AgGridReact
                                        unSortIcon={true}
                                        rowHeight={60}
                                        defaultColDef={AgGridDefaultColDef}
                                        columnDefs={[
                                            {
                                                suppressMovable: true,
                                                headerName: "Code", field: "service",
                                                flex: 1, sortable: true, cellClass: "grid-cell",
                                                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                    if (valueA?.code == valueB?.code) return 0;
                                                    return (valueA?.code > valueB?.code) ? 1 : -1;
                                                },
                                                cellRenderer: (params) => <span>{params.value?.code}</span>
                                            },
                                            // {
                                            //     suppressMovable: true,
                                            //     headerName: "Service", field: "service",
                                            //     flex: 3, sortable: true, cellClass: "grid-cell",
                                            //     comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                            //         if (valueA?.title == valueB?.title) return 0;
                                            //         return (valueA?.title > valueB?.title) ? 1 : -1;
                                            //     },
                                            //     cellRenderer: (params) => <span>{params.value?.title}</span>
                                            // },
                                            // {
                                            //     suppressMovable: true,
                                            //     headerName: "HSN/SAC", field: "service",
                                            //     flex: 1.5, sortable: true, cellClass: "grid-cell",
                                            //     comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                            //         if (valueA?.hsnSacCode == valueB?.hsnSacCode) return 0;
                                            //         return (valueA?.hsnSacCode > valueB?.hsnSacCode) ? 1 : -1;
                                            //     },
                                            //     cellRenderer: (params) => <span>{params.value?.hsnSacCode}</span>
                                            // },
                                            {
                                                suppressMovable: true,
                                                headerName: "Type", field: "type",
                                                flex: 1.5, sortable: true, cellClass: "grid-cell",
                                                cellRenderer: (params) => <span>{PoType[Number(params.value)]}</span>
                                            },
                                            {
                                                suppressMovable: true,
                                                headerName: "Description", field: "description",
                                                flex: 3, sortable: true, cellClass: "grid-cell",
                                                cellRenderer: (params) => <span>{params.value}</span>
                                            },
                                            {
                                                suppressMovable: true,
                                                headerName: "Total Qty", field: "quantity",
                                                flex: 1, sortable: true, cellClass: "grid-cell",
                                                cellRenderer: (params) => <span>{params.value}</span>
                                            },
                                            {
                                                suppressMovable: true,
                                                headerName: "Rate", field: "unitRate",
                                                flex: 1.5, sortable: true, cellClass: "grid-cell",
                                                cellRenderer: (params) => <span>{params.value}</span>
                                            },
                                            {
                                                suppressMovable: true,
                                                headerName: "Measurement", field: "unitMeasurement",
                                                flex: 2, sortable: true, cellClass: "grid-cell",
                                                cellRenderer: (params) => <span>{params.value}</span>
                                            },
                                            {
                                                suppressMovable: true,
                                                headerName: "Amount", field: "totalAmount",
                                                flex: 1.5, sortable: true, cellClass: "grid-cell",
                                                cellRenderer: (params) => <span>{params.value}</span>
                                            },
                                            {
                                                suppressMovable: true,
                                                headerName: "Assignee", field: "totalAmount",
                                                flex: 3, sortable: true, cellClass: "grid-cell",
                                                cellRenderer: (params) => <span>
                                                    <div className="d-flex align-items-center">
                                                        {params.data?.
                                                            purchaseOrderItemWorkAllocation?.user ?
                                                            params.data?.
                                                                purchaseOrderItemWorkAllocation?.user?.firstName +
                                                            '\t'
                                                            +
                                                            params.data?.
                                                                purchaseOrderItemWorkAllocation?.user?.lastName
                                                            :
                                                            'Not Assigned'
                                                        }
                                                    </div>
                                                </span>
                                            },
                                            {
                                                suppressMovable: true,
                                                width:155,
                                                headerName: "Action", field: "totalAmount",
                                                cellClass: "grid-cell",
                                                cellRenderer: (params) =>
                                                    <button className="btn btn--primary-d2 font-12 px-2 py-1 m-0"
                                                        onClick={() => this.setState({
                                                            ...this.state, toggle: !this.state.toggle, purchaseOrderItem: params.data ?? null
                                                        })}
                                                    >Change Assignee</button>
                                            }
                                        ]}
                                        pagination={true}
                                        paginationAutoPageSize={true}
                                        rowData={this.props.workflowData?.purchaseOrderItems}>
                                    </AgGridReact>
                                })()}
                            </div>
                        </article>
                    )
                })()
            }
            <article className={`card mb-4 ${this.vResult("tasks").className}`}>
                <div className="card-header card-form-header child-grid-title justify-content-between d-flex align-items-center">
                    <div className="card-form-header-title">Tasks<span className="requried-span">*</span></div>
                    <Link className="btn btn-primary btn--right m-0" to={ScreenUrls.Workflows.Task.Create(this.props.workflowData.id)}>
                        Add New
                    </Link>
                    <ValidationMessageControl message={this.vResult("tasks").message} />
                </div>

                <div className="card-block ag-theme-alpine mini-grid">
                    {(() => {
                        return <AgGridReact
                            unSortIcon={true}
                            rowHeight={60}
                            defaultColDef={AgGridDefaultColDef}
                            columnDefs={[
                                {
                                    suppressMovable: true,
                                    headerName: "#", field: "id", width: 100, sortable: true, cellClass: "grid-cell",
                                    cellRenderer: (params) => <span>{params.value == 0 ? ` - ` : params.value}</span>
                                },
                                {
                                    suppressMovable: true,
                                    headerName: "Report No", field: "reportNumber", width: 100, sortable: true, cellClass: "grid-cell",
                                    cellRenderer: (params) => <span>{params?.value ?? "NA"}</span>
                                },

                                {
                                    suppressMovable: true,
                                    headerName: "Assignee", field: "assignee", flex: 1, sortable: true, cellClass: "grid-cell",
                                    cellRenderer: (params) => <span>{DropdownDisplayHelper.User(params.value)}</span>
                                },
                                {
                                    suppressMovable: true,
                                    headerName: "Category", field: "category", flex: 1, sortable: true, cellClass: "grid-cell",
                                    cellRenderer: (params) => <span>{params.value?.name}</span>
                                },
                                {
                                    suppressMovable: true,
                                    headerName: "Type", field: "purchaseOrderItem", flex: 1, sortable: true, cellClass: "grid-cell",
                                    cellRenderer: (params) => <span>{PoType[params.value?.type]}</span>
                                },
                                {
                                    suppressMovable: true,
                                    headerName: "Description", field: "description", flex: 3, sortable: true, cellClass: "grid-cell",
                                    cellRenderer: (params) => <span>{params.value}</span>
                                },
                                {
                                    suppressMovable: true,
                                    headerName: "Stage", field: "currentStage", flex: 1, sortable: true, cellClass: "grid-cell",
                                    cellRenderer: (params) => <span>{params.value?.name}</span>
                                },
                                {
                                    suppressMovable: true,
                                    headerName: "Quantity", field: "quantity", flex: 1, sortable: true, cellClass: "grid-cell",
                                    cellRenderer: (params) => <span>{params.value}</span>
                                },
                                {
                                    suppressMovable: true,
                                    headerName: "Priority", field: "priority", flex: 1, sortable: true, cellClass: "grid-cell",
                                    cellRenderer: (params) => <span>{WorkflowTaskPriority[params.value]}</span>
                                },
                                {
                                    suppressMovable: true,
                                    headerName: "Action", field: "id", width: 130, cellClass: "grid-cell grid-cell-button",
                                    cellRenderer: (params) => <Link to={ScreenUrls.Workflows.Task.View(params.value, this.props.workflowData.id)}>
                                        View Task
                                    </Link>
                                },
                                {
                                    suppressMovable: true,
                                    headerName: "Status", field: "", width: 100, cellClass: "grid-cell grid-cell-button",
                                    cellRenderer: (params) => {
                                        return <span className={`pill ${params.data?.isComplete ? "inactive-pill" : "active-pill"}`}>{params.data?.isComplete ? "Closed" : "Open"}</span>
                                    }
                                }
                            ]}
                            pagination={true}
                            paginationAutoPageSize={true}
                            rowData={this.props.workflowData.tasks ?? []}>
                        </AgGridReact>
                    })()}
                </div>
            </article>
            <ButtonGroupComponent>
                <>
                    <button type="button" className="btn btn--secondary btn--right"
                        disabled={this.props.stageMoveLoading}
                        onClick={e => {
                            e.preventDefault();

                            const error = !this.ValidateForm(this.props.workflowData);
                            this.reloadForm();
                            if (error) return;
                            // ---- open Stage assignment modal ----
                            this.props.openStageAssignmentModal({
                                optionsList: this.props.workflowData.nextStageAssigneeList,
                                openModal: true,
                                proceedBtnClicked: true,
                            });

                        }}>Proceed</button>
                    <button type="button" className="btn btn--red btn--right"
                        disabled={this.props.stageMoveLoading}
                        onClick={e => {
                            e.preventDefault();
                            // ---- open Stage assignment modal ----
                            this.props.openStageAssignmentModal({
                                optionsList: this.props.workflowData.rejectStageAssigneeList,
                                openModal: true,
                                proceedBtnClicked: false,
                            });

                        }}>Reject</button>
                    <button type="button" className="btn btn--primary-d2 btn--right"
                        disabled={this.props.stageMoveLoading}
                        onClick={e => {
                            e.preventDefault();

                            this.props.editWorkflow(this.props.workflowData);
                            this.props.onChange();
                        }}>Save</button>
                </>
            </ButtonGroupComponent>
            {/* --- work allocation --- */}
            {
                this.state.toggle &&
                <WorkAllocationModal
                    toggle={this.state.toggle}
                    purchaseOrderItem={this.state.purchaseOrderItem}
                    workflowData={this.props.workflowData}
                    setHideModal={() => this.setState({ ...this.state, toggle: !this.state.toggle, purchaseOrderItem: null })}
                />
            }

            {(() => { if (this.props.stageMoveLoading) return <div className="loading--bar fixed--top"><span></span></div> })()}
        </React.Fragment>
    }
}

const mapStateToProps = (state: IAppState) => ({
    workflowData: state.workflow.workflowData,
    stageMoveLoading: state.workflow.stageMoveLoading,
})
export default connect(mapStateToProps, { openStageAssignmentModal, editWorkflow, updateWorkflowDataState })(TasksWorkflowComponent);
