import { PureComponent } from "react";
import { displayDate } from "../../../common/utils";
import ScreenUrls from "../../../common/screen-urls";
import { Link } from "react-router-dom";
import { AccessLevel } from "../../../common/enums";
import GridDeleteButtonControl from "../../../common/control/component/grid/grid-delete-buton.control";
import DataLoggerService from "../service/data-logger.service";
import { loadDataLoaders } from "../store/data-logger.slice";
import { connect } from "react-redux";
import { IDataLoggerFilterRequestModel } from "../model/request/data-logger-filter.rquest.model";
import FileService from "../../../common/service/file.service";

interface IProps {
    item: any,
    accessLevel: number,
    loadDataLoaders: (request?: IDataLoggerFilterRequestModel) => void
}

class DataLoggerItemCardComponent extends PureComponent<IProps, any> {

    render() {
        return (<div className="col-6 col-lg-4 my-2">
            <div className="data-logger-card-item h-100 d-flex flex-column justify-content-between">
                <div>
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            Name : {this.props.item?.name}
                        </div>
                        <div className="d-flex align-items-center">

                            {
                                this.props.accessLevel >= AccessLevel.Edit && this.props.item?.isAvailable &&
                                <Link to={ScreenUrls.DataLoggers.Edit(this.props.item?.id)} className="mr-2">
                                    <i className={`feather icon-edit`}></i>
                                </Link>
                            }
                            {
                                this.props.accessLevel >= AccessLevel.Delete && this.props.item?.isAvailable &&
                                <GridDeleteButtonControl onDelete={async () => {
                                    await DataLoggerService.Delete(this.props.item?.id);
                                    this.props.loadDataLoaders();
                                }}></GridDeleteButtonControl>
                            }

                        </div>
                    </div>

                    {
                        !this.props.item?.isAvailable &&  !this.props.item?.isDamaged && !this.props.item?.isExpired &&
                        <>
                            <div>
                                Client : {this.props.item?.booking[0]?.client?.companyName}
                            </div>
                            <div>
                                Booking modified on : {displayDate(this.props.item?.booking[0]?.lastUpdatedOnUtc ?? this.props.item?.booking[0]?.createdOnUtc)}
                            </div>
                        </>
                    }
                    <div>
                        Category : {this.props.item?.category?.name}
                    </div>
                    <div>
                        Make : {this.props.item?.make?.name}
                    </div>

                    <div>
                        Serial : {this.props.item?.serialNumber}
                    </div>
                    <div>
                        Last calibrate  :  {displayDate(this.props.item?.lastCalibratedOn)}
                    </div>
                    <div>
                        Calibration due :   {displayDate(this.props.item?.calibrationDueOn)}
                    </div>
                </div>
                <div>
                    <div className="d-flex align-items-center mt-2">
                       
                        <div className={`pill text-capitalize font-12 ${this.props.item?.isDamaged ? 'inactive-red' : 'active-pill'} mr-2`}>
                            {this.props.item?.isDamaged ? 'Damaged' : 'Healthy'}
                        </div>
                        <div className={`pill text-capitalize font-12 ${this.props.item?.isExpired ? "inactive-red" : !this.props.item?.isAvailable ? 'inactive-pill' : 'active-pill'}`}>
                            {this.props.item?.isExpired ? "Expired" : this.props.item?.isAvailable ? 'Available' : 'Booked'}
                        </div>
                        {this.props.item?.calibrationCertificate && this.props.item?.calibrationCertificate?.publicLink &&
                            <button
                                style={{ borderRadius: "15px" }}
                                className="text-capitalize font-12 m-0 ml-2 pill active-pill align-self-end"
                                onClick={e => {
                                    e.preventDefault();
                                    window.open(this.props.item?.calibrationCertificate?.publicLink, '_blank');
                                }}>Certificate Link</button>
                        }
                    </div>
                </div>


            </div>
        </div>)

    }
}



export default connect(null, { loadDataLoaders })(DataLoggerItemCardComponent);