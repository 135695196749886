import { IStoreSaveModel } from "../../../../common/model/store-save.model";
import { IWorkflowTaskModel } from "../../model/workflow-task.model";
import { DeferredLoadOptions, displayDate, fullDisplayDateInMilitaryTime } from "../../../../common/utils";
import { IFormControlDropdownItemModel } from "../../../../common/control/model/form.control.field.model";
import { IUserModel } from "../../../user/model/user.model";
import DropdownDisplayHelper from "../../../../common/helper/dropdown-display.helper";
import { IAppState } from "../../../../store";
import { connect } from "react-redux";
import CommentComponent from "../../../../common/component/comments.component";
import { loadWorkflowById, clearWorkflowDataState } from "../../../workflow/store/workflow.slice";
import {
    clearWorkflowTaskDataState,
    editWorkflowTask,
    loadWorkflowTaskById, setToDefaultStageFlow,
    setWorkflowTaskDataState,
    updateWorkflowTaskDataState,
    moveToNextWorkflowTaskStage, moveToWorkflowTaskRejectStage,
    watchWorkflowTask,
} from "../../store/workflow-task.slice";
import { Link, Navigate } from "react-router-dom";
import ScreenUrls from "../../../../common/screen-urls";
import StageFlowStepperComponent from "../../../workflow/component/stage-flow-stepper.component";
import AsyncSelect from "react-select/async";
import UserService from "../../../user/service/user.service";
import { AccessLevel, EntityType, WorkflowTaskStage } from "../../../../common/enums";
import { AgGridReact } from "ag-grid-react";
import React from "react";
import BookingWorkflowTaskComponent from "../../component/stages/3-booking-workflow-task.component";
import ClientReviewWorkflowTaskComponent from "../../component/stages/11-client-review-workflow-task.component";
import ReportPreparationWorkflowTaskComponent from "../../component/stages/7-reporting-preparation-workflow-task.component";
import ReportReviewWorkflowTaskComponent from "../../component/stages/8-report-review-workflow-task.component";
import ActivityPlaningWorkflowTaskComponent from "../../component/stages/2.activity-planning-task.component";
import OperationsReviewWorkflowTaskComponent from "../../component/stages/5-operations-review-workflow-task.component";
import CreationWorkflowTaskComponent from "../../component/stages/1-creation-workflow-task.component";
import MultiFileControl from "../../../../common/control/file/multi-file.control";
import SiteActivityWorkflowTaskComponent from "../../component/stages/4-site-activity-workflow-task.component";
import ReportDeliveryWorkflowTaskComponent from "../../component/stages/12-report-delivery-workflow-task.component";
import { AgGridDefaultColDef } from "../../../../common/app-defaults";
import { FormBaseComponent, IFormBaseState } from "../../../../common/component/form-base.component";
import ValidationMessageControl from "../../../../common/control/validation-message.control";
import { IWorkflowModel } from "../../../workflow/model/workflow.model";
import ReportAssignmentWorkflowTaskComponent from "../../component/stages/6-report-assignment-workflow-task.component";
import QaAssignmentWorkflowTaskComponent from "../../component/stages/9-qa-assignment-workflow-task.component";
import QaReview from "../../component/stages/10-quality-assurance-review-workflow-task.component";
import WorkflowTaskClosureComponent from "../../component/stages/14-task-closure-workflow-task.component";
import StageAssignmentModalComponent from "../../../../common/component/stage-assignment-modal.component";
import { IStageAssignment } from "../../../../common/stores/stage-assignment.slice";
import WorkflowTaskInvoicingComponent from "../../component/stages/13-invoicing-workflow-task.component";
import DropdownService from "../../../../common/service/dropdown.service";

export interface IProps {
    accessLevel: number,
    id?: string;
    workflowId?: string;
    workflowData: IWorkflowModel;
    workflowTaskData: IWorkflowTaskModel;
    loadWorkflowById: (id: number) => void;
    loadWorkflowTaskById: (id: number) => void;
    editWorkflowTask: (data: IWorkflowTaskModel) => void;
    setWorkflowTaskDataState: (payload: IWorkflowTaskModel) => void;
    updateWorkflowTaskDataState: (payload: IStoreSaveModel) => void;
    clearWorkflowTaskDataState: () => void;
    clearWorkflowDataState: () => void;
    setToDefaultStageFlow: () => void;
    dataLoading: boolean;
    workflowDataLoading: boolean;
    createEditLoading: boolean;
    setToDefaultStageFlowLoading: boolean;
    currentUser: IUserModel;
    stageAssignment: IStageAssignment;
    moveToNextWorkflowTaskStage: (payload: IWorkflowTaskModel) => void;
    moveToWorkflowTaskRejectStage: (payload: IWorkflowTaskModel) => void;
    watchWorkflowTask: (query: { id: number, watching: boolean }) => void,
}

export interface IState extends IFormBaseState {
    hideAssignee: boolean
}

class WorkflowTaskCreateEditPage extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            hideAssignee: false,
            hideForm: false
        }
    }

    async componentDidMount() {

        if (this.props.workflowId != null && this.props.workflowId != "" && Number(this.props.workflowId) > 0) {
            await this.props.loadWorkflowById(Number(this.props.workflowId));
        }

        if (this.props.id != null && this.props.id != "" && Number(this.props.id) > 0) {
            await this.props.loadWorkflowTaskById(Number(this.props.id));
        } else {
            await this.props.clearWorkflowTaskDataState();
            await this.props.setToDefaultStageFlow();
        }

        this.reloadAssignee();
    }

    loadAssigneeOption = (inputValue: string) => DeferredLoadOptions(inputValue, (search: string) => new Promise<IFormControlDropdownItemModel[]>(async (resolve) => {
        if (this.props.workflowTaskData == null
            || this.props.workflowTaskData.currentStage == null
            || this.props.workflowTaskData.currentStage.team == null) {
            let remaining: any = await DropdownService.WorkAllocationTeamMembersDropdown();
            let list: any[] = [];
            for (let i = 0; i < remaining?.length; i++) {
                list.push({
                    label: `${remaining[i]?.firstName} ${remaining[i]?.lastName}`,
                    value: remaining[i].id?.toString(),
                });
            }
            if (search == null || search === "") resolve(list);
            resolve(list.filter(l => l.label.includes(search)))
            return;
        }

        let assignees: IUserModel[] = this.props.workflowTaskData.currentStage.onlyForLeads == true
            ? this.props.workflowTaskData.currentStage.team.teamLeads
            : [
                ...this.props.workflowTaskData.currentStage.team.teamLeads,
                ...this.props.workflowTaskData.currentStage.team.members
            ];

        if (assignees == null || assignees.length == 0) {
            resolve([]); return;
        }

        const list = assignees.map(d => {
            const obj: IFormControlDropdownItemModel = {
                label: DropdownDisplayHelper.User(d),
                value: d.id.toString()
            }
            return obj;
        });

        if (search == null || search === "") resolve(list);

        const filter = list.filter(i => i.label.toLowerCase().trim().includes(search.toLowerCase().trim()))
        resolve(filter);
    }));

    reloadAssignee = () => {
        this.setState({ hideAssignee: true });
        setTimeout(() => {
            this.setState({ hideAssignee: false });
        }, 1);
    }

    onStageComponentChange = () => {
        this.reloadAssignee();
    }

    componentWillUnmount(): void {
        this.props.clearWorkflowTaskDataState();
        this.props.clearWorkflowDataState();
    }

    render() {
        if (this.props.accessLevel < Number(AccessLevel.Edit) && !this.props.dataLoading && this.props.workflowTaskData.assigneeId && this.props.workflowData.assigneeId) {
            if (this.props.workflowData?.editEnabled || this.props.workflowTaskData?.editEnabled) {
                if (this.props.workflowTaskData?.id == 0 || this.props.workflowTaskData?.id == null) {
                    this.props.updateWorkflowTaskDataState({ name: "assignee", value: this.props.currentUser});
                    this.props.updateWorkflowTaskDataState({ name: "assigneeId", value: this.props.currentUser.id})
                }
            } else {
                return <Navigate to={ScreenUrls.AccessDenied()} replace />
            }
        }

        if (this.state.hideForm) return;
        return <React.Fragment>
            {(() => {
                if (this.props.dataLoading
                    || this.props.workflowDataLoading
                    || this.props.createEditLoading
                    || this.props.setToDefaultStageFlowLoading)
                    return <div className="loading--bar fixed--top"><span></span></div>
            })()}

            <div className="pcoded-content">
                <div className="pcoded-inner-content">
                    <div className="page-header">
                        <div className="page-block">
                            <div className="row">
                                <div className="col-md-12 d-flex align-items-center justify-content-between">
                                    {(() => {
                                        const link = this.props.id == undefined || this.props.id == null || this.props.id == "" || this.props.id == "0"
                                            ? ScreenUrls.Workflows.Edit(this.props.workflowId)
                                            : ScreenUrls.Workflows.Task.View(this.props.id, this.props.workflowId);

                                        return <Link to={link} className="btn drp-icon btn-rounded btn-primary dropdown-toggle">
                                            <i className="feather icon-arrow-left"></i>
                                        </Link>
                                    })()}

                                    {
                                        this.props.workflowTaskData?.id > 0 &&
                                        <button className="border-0 bg-transparent d-flex align-items-center"
                                            disabled={this.props.workflowTaskData?.isComplete}
                                            onClick={async () => {
                                                await this.props.watchWorkflowTask({ id: this.props.workflowTaskData.id, watching: !this.props.workflowTaskData?.isWatching });
                                                this.setState({ ...this.state, hideForm: true });
                                                setTimeout(() => {
                                                    this.setState({ ...this.state, hideForm: false });
                                                }, 1)
                                            }}
                                        >

                                            <i
                                                className={`feather mr-2 mb-0 h5 icon-eye${this.props.workflowTaskData.isWatching ? "-off" : ""
                                                    }`}
                                            ></i>
                                            {this.props.workflowTaskData.isWatching ? "Un-watch" : "Watch"}
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-body">
                        <div className="page-wrapper">
                            <div className="row">
                                <div className="col-sm-12">

                                    {/* Workflow Stage Stepper */}
                                    {(() => {
                                        if (this.props.workflowTaskData.stageFlow == null || this.props.workflowTaskData.stageFlow.length == 0) return;
                                        return <StageFlowStepperComponent
                                            stageFlow={this.props.workflowTaskData.stageFlow}
                                            isCompleted={this.props.workflowTaskData.isComplete}
                                            customClass={"workflow-task-stages"}
                                        />
                                    })()}

                                    {/* Workflow Basic Info */}
                                    {(() => {
                                        if (this.props.workflowTaskData == null || this.state.hideForm) return;
                                        return <article className="card no-bg mb-4">
                                            <div className="card-header card-form-header">
                                                <div className="row">
                                                    <div className="col-2">
                                                        <div className="form-group read-only">
                                                            <label>Category</label>
                                                            <p>{this.props.workflowTaskData?.category?.name ?? ""}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-2">
                                                        <div className="form-group read-only">
                                                            <label>Team</label>
                                                            <p>{this.props.workflowTaskData?.currentStage?.team?.displayName ?? ""}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-4">
                                                        <div className={`form-group ${this.vResult("assigneeId").className}`}>
                                                            <label htmlFor="assigneeId">Assignee<span className="requried-span">*</span></label>
                                                            {(() => {
                                                                if (this.state.hideAssignee) return;
                                                                return <AsyncSelect id="assigneeId" cacheOptions defaultOptions
                                                                    className="async-select-control"
                                                                    value={(this.props.workflowData?.currentStage?.name == "Tasks" && this.props.workflowData?.assignee) || this.props.workflowTaskData.assignee != null
                                                                        ? {
                                                                            label: DropdownDisplayHelper.User(this.props.workflowTaskData.assignee ?? this.props.workflowData?.assignee ?? null),
                                                                            value: this.props.workflowTaskData?.assignee?.id?.toString() ?? this.props.workflowData?.assignee?.id?.toString()
                                                                        }
                                                                        : { label: "Select Assignee", value: "" }}
                                                                    loadOptions={this.loadAssigneeOption}
                                                                    onChange={async (e) => {
                                                                        const uId = Number(e?.value);
                                                                        this.ValidateField("assigneeId", uId);

                                                                        if (uId == null || uId == 0 || uId == this.props.workflowTaskData.assigneeId) return;

                                                                        const user = await UserService.GetById(uId);
                                                                        if (user == null) return;

                                                                        this.props.updateWorkflowTaskDataState({ name: "assignee", value: user });
                                                                        this.props.updateWorkflowTaskDataState({ name: "assigneeId", value: user.id })
                                                                        this.reloadAssignee();
                                                                    }} />
                                                            })()}
                                                            <ValidationMessageControl message={this.vResult("assigneeId").message} />
                                                        </div>
                                                    </div>
                                                    <div className="col-4">
                                                        <Link to={ScreenUrls.Workflows.Edit(this.props.workflowId)}
                                                            className="btn btn-primary btn--row-right">
                                                            Open Workflow
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    })()}

                                    {/* Workflow Stage Components */}
                                    {(() => {
                                        let workflowTaskStage: WorkflowTaskStage = this.props.workflowTaskData.currentStage
                                            ? WorkflowTaskStage[this.props.workflowTaskData.currentStage.name]
                                            : WorkflowTaskStage.None;
                                        switch (workflowTaskStage) {
                                            case WorkflowTaskStage.TaskCreation:
                                                return <CreationWorkflowTaskComponent onChange={this.onStageComponentChange}
                                                    accessLevel={this.props.accessLevel}
                                                    workflowId={this.props.workflowId == undefined || this.props.workflowId == null || this.props.workflowId == ""
                                                        ? 0 : Number(this.props.workflowId)} />
                                            case WorkflowTaskStage.ActivityPlanning:
                                                return <ActivityPlaningWorkflowTaskComponent onChange={this.onStageComponentChange} />
                                            case WorkflowTaskStage.Booking:
                                                return <BookingWorkflowTaskComponent onChange={this.onStageComponentChange} />
                                            case WorkflowTaskStage.SiteActivity:
                                                return <SiteActivityWorkflowTaskComponent onChange={this.onStageComponentChange} />
                                            case WorkflowTaskStage.OperationsReview:
                                                return <OperationsReviewWorkflowTaskComponent onChange={this.onStageComponentChange} />
                                            case WorkflowTaskStage.ReportAssignment:
                                                return <ReportAssignmentWorkflowTaskComponent onChange={this.onStageComponentChange} />
                                            case WorkflowTaskStage.ReportPreparation:
                                                return <ReportPreparationWorkflowTaskComponent onChange={this.onStageComponentChange} />
                                            case WorkflowTaskStage.ReportReview:
                                                return <ReportReviewWorkflowTaskComponent onChange={this.onStageComponentChange} />
                                            case WorkflowTaskStage.QaAssignment:
                                                return <QaAssignmentWorkflowTaskComponent onChange={this.onStageComponentChange} />
                                            case WorkflowTaskStage.QaReview:
                                                return <QaReview onChange={this.onStageComponentChange} />
                                            case WorkflowTaskStage.ClientReview:
                                                return <ClientReviewWorkflowTaskComponent onChange={this.onStageComponentChange} />
                                            case WorkflowTaskStage.ReportDelivery:
                                                return <ReportDeliveryWorkflowTaskComponent onChange={this.onStageComponentChange} />
                                            case WorkflowTaskStage.Invoicing:
                                                return <WorkflowTaskInvoicingComponent onChange={this.onStageComponentChange} />
                                            case WorkflowTaskStage.TaskClosure:
                                                return <WorkflowTaskClosureComponent onChange={this.onStageComponentChange} />
                                        }
                                    })()}

                                    {
                                        (() => {
                                            if (this.props.workflowTaskData.id < 1) return;
                                            return (
                                                <CommentComponent
                                                    hideActions={this.props.workflowTaskData?.isComplete}
                                                    entityType={EntityType.WorkflowTask} entityId={this.props.workflowTaskData?.id} />
                                            )
                                        })()
                                    }

                                    {/* Files */}
                                    {(() => {
                                        if (this.props.workflowTaskData != null && this.props.workflowTaskData.id > 0) {
                                            return <MultiFileControl title="Task Files"
                                                entityType={EntityType.WorkflowTask}
                                                entityId={this.props.workflowTaskData.id}
                                                editable={!this.props.workflowTaskData.isComplete}
                                            />
                                        }
                                    })()}

                                    {/* Stage Change History */}
                                    {(() => {
                                        if (this.props.workflowTaskData.stageChangeHistory == null || this.props.workflowTaskData.stageChangeHistory.length == 0) return;
                                        return <article className="card mb-4">
                                            <div className="card-header card-form-header">
                                                <div className="card-form-header-title">Audit Trail</div>
                                            </div>
                                            <div className="card-block ag-theme-alpine mini-grid">
                                                <AgGridReact
                                                    unSortIcon={true}
                                                    rowHeight={60}
                                                    pagination={true}
                                                    paginationAutoPageSize={true}
                                                    rowData={this.props.workflowTaskData.stageChangeHistory}
                                                    defaultColDef={AgGridDefaultColDef}
                                                    columnDefs={[
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "Created On", field: "createdOn", flex: 1, sortable: true, cellClass: "grid-cell",
                                                            sort: "desc",
                                                            cellRenderer: (params) => <span>{fullDisplayDateInMilitaryTime(params.value)}</span>
                                                        },
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "Stage Change", field: "stageChangedFrom", flex: 3, sortable: true, cellClass: "grid-cell",
                                                            cellRenderer: (params) => <React.Fragment>
                                                                <span className="grid-cell-stage-from">{params.value?.name}</span>
                                                                <i className="feather icon-arrow-right grid-cell-stage-icon"></i>
                                                                <span className="grid-cell-stage-to">{params.data.stageChangedTo?.name}</span>
                                                            </React.Fragment>
                                                        },
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "Description", field: "description", flex: 4, sortable: true, cellClass: "grid-cell",
                                                            cellRenderer: (params) => <span>{params.value}</span>
                                                        },
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "Created By", field: "createdBy", flex: 1.5, sortable: true, cellClass: "grid-cell",
                                                            cellRenderer: (params) => <span>
                                                                {params.value == null ? "Unassigned" : `${params.value?.firstName} ${params.value?.lastName}`}
                                                            </span>
                                                        }
                                                    ]}>
                                                </AgGridReact>
                                            </div>
                                        </article>
                                    })()}

                                    {/* Workflow Task Change History */}
                                    {(() => {
                                        if (this.props.workflowTaskData.historyLog == null) return;
                                        return <article className="card mb-4">
                                            <div className="card-header card-form-header">
                                                <div className="card-form-header-title">Overall Change History</div>
                                            </div>
                                            <div className="card-block ag-theme-alpine mini-grid">
                                                <AgGridReact
                                                    unSortIcon={true}
                                                    rowHeight={60}
                                                    pagination={true}
                                                    paginationAutoPageSize={true}
                                                    rowData={this.props.workflowTaskData.historyLog}
                                                    defaultColDef={AgGridDefaultColDef}
                                                    columnDefs={[
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "DateTime", field: "datetime", flex: 1, sortable: true, cellClass: "grid-cell",
                                                            sort: "desc",
                                                            cellRenderer: (params) => <span>{displayDate(params.value)}</span>
                                                        },
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "Description", field: "description", flex: 1.5, sortable: true, cellClass: "grid-cell",
                                                            cellRenderer: (params) => <span>{params.value}</span>
                                                        },
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "User", field: "user", flex: 2, sortable: true, cellClass: "grid-cell",
                                                            cellRenderer: (params) => <span>
                                                                {params.value == null ? "Unassigned" : `${params.value?.firstName} ${params.value?.lastName}`}
                                                            </span>
                                                        }
                                                    ]}>
                                                </AgGridReact>
                                            </div>
                                        </article>
                                    })()}

                                    {/* ---- modal popup for assigning workflow ------ */}
                                    {
                                        this.props.stageAssignment.openModal &&

                                        <StageAssignmentModalComponent
                                            heading={"Assign workflow task"}
                                            proceedCallBack={async (obj: any) => {
                                                if (obj) {
                                                    await this.props.moveToNextWorkflowTaskStage({ ...this.props.workflowTaskData, toBeAssignedTo: obj.id });
                                                    this.onStageComponentChange();
                                                }
                                            }}
                                            rejectCallBack={async (obj: any) => {
                                                if (obj) {
                                                    await this.props.moveToWorkflowTaskRejectStage({ ...this.props.workflowTaskData, toBeAssignedTo: obj.id });
                                                    this.onStageComponentChange()

                                                }

                                            }}


                                        />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}

const mapStateToProps = (state: IAppState) => ({
    workflowData: state.workflow.workflowData,
    workflowTaskData: state.workflowTask.workflowTaskData,
    dataLoading: state.workflowTask.dataLoading,
    workflowDataLoading: state.workflow.dataLoading,
    createEditLoading: state.workflowTask.createEditLoading,
    currentUser: state.userSession.currentUser,
    stageAssignment: state.stageAssignment,
    setToDefaultStageFlowLoading: state.workflowTask.setToDefaultStageFlowLoading
})
export default connect(mapStateToProps, {
    loadWorkflowTaskById,
    loadWorkflowById,
    editWorkflowTask,
    setWorkflowTaskDataState,
    updateWorkflowTaskDataState,
    setToDefaultStageFlow,
    clearWorkflowTaskDataState,
    clearWorkflowDataState,
    moveToNextWorkflowTaskStage, moveToWorkflowTaskRejectStage,
    watchWorkflowTask,
})(WorkflowTaskCreateEditPage);
